import { Component, ElementRef, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { FormComponent, ISelectInput, ISimpleInput } from '../form/form.component';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SanitizeInputDirective } from '../../directives/inputSanitize.directive';
import { MatInputModule } from '@angular/material/input';
import { IonicModule } from '@ionic/angular';
import { TimeRangeSelector } from '../time-range-selector/time-range-selector.component';
import { MatSliderModule } from '@angular/material/slider';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

declare var apiRTC: any;

@Component({
  selector: 'timingComponentsGroup',
  templateUrl: './timingComponentsGroup.component.html',
  styleUrls: ['./timingComponentsGroup.component.scss'],
  standalone: true,
  imports: [ 
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatGridListModule,
    MatFormFieldModule,
    MatSelectModule,
    TranslateModule,
    ReactiveFormsModule,
    MatInputModule,
    TimeRangeSelector,
    MatButtonToggleModule,
    MatSliderModule,
    IonicModule
  ]
})
export class TimingComponentsGroup implements OnInit {
  
  /**
   * @description FormGroup che regola tutti i campi della form nel component 
   */
  public formGroup!: FormGroup;
  public formArray!: FormArray;
  public hasError: boolean = false;
  public isHandleTouched: boolean = false;
  @ViewChild(TimeRangeSelector) child!: TimeRangeSelector;

  constructor(
  ) { }

  ngOnInit() {
  }

  refreshCirclePosition() {    
    setTimeout(() => {
      this.child.refreshCirclePosition();
    }, 500);
  }

  handleTouched(isHandleTouched: boolean) {
    this.isHandleTouched = isHandleTouched;
  }

  onScroll() {
    this.child.refreshCirclePosition();
  }
}
