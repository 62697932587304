import { Component, EnvironmentInjector, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './core/services/auth.service';
import { PopoverController } from '@ionic/angular';
import { SwUpdate } from '@angular/service-worker';
import { RequestsService } from './core/services/utils/requests.service';
import { environment } from '../environments/environment';
import { NotificationService } from './core/services/notification.service';
import { UserService } from './core/services/user.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  public environmentInjector = inject(EnvironmentInjector);
  //public navigationService = inject(NavigationService)
  public popoverController = inject(PopoverController)
  public authService = inject(AuthService);
  public isPwa: boolean = false;
  public isMobile: boolean = false;

  deviceOffline: boolean

  constructor(
    private translateService: TranslateService,
    private swUpdate: SwUpdate,
    private requestsService: RequestsService,
    private notificationService: NotificationService,
  ) {

    this.deviceOffline = false;

    /* Network.addListener('networkStatusChange', status => {
      console.log('Network status changed', status);
      if (status.connected === false) {
        this.deviceOffline = true
      } else {
        this.deviceOffline = false
      }
    }); */
    
    this.initializeLanguage(navigator.language);
    if(this.authService.isLoggedIn()){
      // all'apertura dell'app con l'utente già loggato, aggiorna il firebase token per sicurezza nel caso in cui sia cambiato
      //this.authService.updateUserDevice$().pipe(take(1)).subscribe();
      // Se l'utente è già loggato allora aggiorno l'Observable contenente i dati dello user dal momento che all'inizio non sarà mai valorizzato
      requestsService.saveCurrentUser(requestsService.getCurrentUser());
      if (requestsService.getCurrentUser()?.building?.id) {
        notificationService.requestPermission();
        notificationService.listen();
      }
    }
    environment.production && this.swUpdate.checkForUpdate().then((foundNewVersion) => {
      if (foundNewVersion) {
        confirm(this.translateService.instant('GENERAL.PWA.NEW_VERSION_AVAILABLE')) && window.location.reload();
      }
    });
  }

  /**
   * @description Imposto le traduzioni sulla base della lingua dello user o, se non esistente, del browser, di default metto 'it'
   * @param browserLanguage 
   */
  initializeLanguage(browserLanguage: string) {
    if (['it', 'en', 'fr'].includes(this.requestsService.getCurrentUser()?.language || "")) {
      this.translateService.use(this.requestsService.getCurrentUser()!.language);
    } else {
        if (browserLanguage.includes('en-')) {
          this.translateService.use('en');
        } else if (browserLanguage.includes('fr-')) {
          this.translateService.use('fr');
        } else {
          this.translateService.use('it');
        }
    }
  }
}
