import { Component, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import {
  Subject,
} from 'rxjs';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { EventService } from '../../../core/services/events.service';

@Component({
  selector: 'pwaInstallation',
  templateUrl: './pwaInstallation.component.html',
  styleUrls: ['./pwaInstallation.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    MatGridListModule,
    MatIconModule,
    TranslateModule,
    RouterModule,
    MatGridListModule,
    IonicModule
]
})
export class PwaInstallationPage implements OnInit {

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public eventService: EventService
  ) {
  }

  ionViewDidEnter() {
  }

  private destroyed$ = new Subject();
  public installPrompt?: any;

  ngOnInit() {
    window.addEventListener("beforeinstallprompt", (event) => {
      event.preventDefault();
      this.installPrompt = event;
    });
    window.addEventListener("appinstalled", () => {
      this.router.navigateByUrl('/auth');
    });
    
  }

  ngOnDestroy() {
    this.destroyed$.next(1);
    this.destroyed$.complete();
  }

  showInstallPwaPrompt() {
    this.installPrompt.prompt();
  }
}