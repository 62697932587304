
<mat-grid-list cols="9" rowHeight="36" gutterSize="10">
  <mat-grid-tile colspan="9" rowspan="1" style="text-align: center;">
    <p style="width: 100%; margin: 0; text-align: center; font-size: 20px;">Monitoraggio</p>
  </mat-grid-tile>
  <mat-grid-tile colspan="9" rowspan="1">
    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" style="width: 100%; height: 100%; display: flex; align-items: center;" hideSingleSelectionIndicator>
      <mat-button-toggle value="always_active" style="width: 100%;">Sempre attivo</mat-button-toggle>
      <mat-button-toggle value="timed" style="width: 100%;">Temporizzato</mat-button-toggle>
      <mat-button-toggle value="disabled" style="width: 100%;">Disattivato</mat-button-toggle>
    </mat-button-toggle-group>
  </mat-grid-tile>
  <!-- TIME RANGE SELECTOR -->
  <mat-grid-tile colspan="9" rowspan="6">
    <div style="margin: auto;">
      <time-range-selector [diameter]="250" (handleTouched)="handleTouched($event)"></time-range-selector>
    </div>
  </mat-grid-tile>
  <mat-grid-tile colspan="9" rowspan="1" style="text-align: center; overflow: visible;">
    <p style="width: 100%; margin: 0; text-align: center; font-size: 20px;">Ritardo allerta uscita letto (min)</p>
  </mat-grid-tile>

  <mat-grid-tile colspan="9" rowspan="1" style="overflow: visible;">
    <mat-slider discrete style="width: 100%;" max="30" step="5">
      <input matSliderThumb>
    </mat-slider>
  </mat-grid-tile>

  <mat-grid-tile colspan="1" rowspan="1" style="overflow: visible;"></mat-grid-tile>
  <mat-grid-tile colspan="7" rowspan="1" style="overflow: visible;">
    <ion-button color="primary" mode="md" style="width: 100%; height: 100%;">
      Salva
    </ion-button>
  </mat-grid-tile>
  <mat-grid-tile colspan="9" rowspan="1" style="overflow: visible;"></mat-grid-tile>
</mat-grid-list>