import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, viewChild, ViewChild } from '@angular/core';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { CircularSliderHandleDirective } from './directives/circular-slider-handle.directive';

@Component({
  selector: 'time-range-selector',
  templateUrl: './time-range-selector.component.html',
  styleUrls: ['./time-range-selector.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    CommonModule,
    CircularSliderHandleDirective,
    MatIcon
  ]
})
export class TimeRangeSelector implements OnInit {
  @ViewChild('circle') circle?: ElementRef;
  @ViewChild('arc') arc?: ElementRef;

  @Input() diameter: number = 300;
  @Output() handleTouched = new EventEmitter(false);

  private _startingHour: Date = new Date();
  private _endingHour: Date = new Date();

  constructor() {
    this._startingHour.setHours(this.startingHour-2);
    this._endingHour.setHours(this.endingHour+2);
    this._angle1 = this.startingHour*15;
    this._angle2 = this.endingHour*15;
    this.startHandleRadiant = ((this.angle1*Math.PI/180)-Math.PI/2);
    this.endingHandleRadiant = ((this.angle2*Math.PI/180)-Math.PI/2);
  }

  onHandleTouch(isHandleTouched: boolean) {
    this.handleTouched.emit(isHandleTouched)
  }

  private _angle1: number;
  private _angle2: number;
  

  public circlePosition?: {x:number; y:number};
  public handleDiameter: number = (this.diameter*0.21)/2;



  get angle1() {
    return this._angle1;
  }
  set angle1(deg: number) {
    this._angle1 = deg;
  }

  get angle2() {
    return this._angle2;
  }
  set angle2(deg: number) {
    this._angle2 = deg;
  }

  get startingHour() {
    return this._startingHour.getHours();
  }
  set startingHour(deg: number) {
    this._startingHour.setHours(deg/15);
  }
  
  get endingHour() {
    return this._endingHour.getHours();
  }
  set endingHour(deg: number) {
    this._endingHour.setHours(deg/15);
  }

  public startHandleRadiant: number;
  public endingHandleRadiant: number;

  ngOnInit() {

  }

  refreshCirclePosition() {
      const rect = this.circle?.nativeElement.getBoundingClientRect();
      this.circlePosition = {
        x: rect.x,
        y: rect.y
      }
  }
  
  ngAfterViewInit() {
    setTimeout(() => {
      this.refreshCirclePosition()
    }, 500);
    if (this.arc) {
      const ctx = this.arc.nativeElement.getContext("2d");
      const x = this.arc.nativeElement.width/2;
      const y = this.arc.nativeElement.height/2;
      const radius = (this.diameter-this.handleDiameter)/2;
      ctx.beginPath();
      ctx.save();
      ctx.lineWidth = this.handleDiameter*0.3;
      ctx.arc(x, y, radius, 0, 1.5*Math.PI);
      ctx.stroke();
      this.refreshArc('start', this.startHandleRadiant);
      this.refreshArc('end', this.endingHandleRadiant);   
    }
  }

  refreshArc(pointType: 'start' | 'end', radiant: number) {
    const ctx = this.arc!.nativeElement.getContext("2d");
    const canvWidth = this.arc!.nativeElement.width;
    const canvHeight = this.arc!.nativeElement.height;
    if (pointType === 'start') {
      this.startHandleRadiant = radiant;
      this.startingHour = radiant * 180/Math.PI+90;
    } else {
      this.endingHandleRadiant = radiant;
      this.endingHour = radiant * 180/Math.PI+90;
    }
    ctx.clearRect(0, 0, canvWidth, canvHeight);
    ctx.beginPath();
    ctx.arc(canvWidth/2, canvHeight/2, (this.diameter-this.handleDiameter)/2, this.startHandleRadiant, this.endingHandleRadiant);
    const gradient = ctx.createRadialGradient(110, 90, 30, 100, 100, 70);
    gradient.addColorStop(0, '#F9D905');
    gradient.addColorStop(1, '#F9A605');
    ctx.strokeStyle = gradient;

    ctx.stroke();
  }
}
