import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { environment } from '../../../environments/environment';
import { createCustomerError, createCustomerSuccess, loadCustomersSuccess, loadingCustomers, loadingCustomersError } from "../../state/actions/customers.actions";
import { BehaviorSubject, Observable, catchError, map, of, take, tap } from "rxjs";
import { ICustomer } from "../../shared/models/be/customers";
import { Measurement } from "../../shared/models/be/measurement";
import { IDevice, IDeviceFacilities, IDeviceLocations } from "../../shared/models/be/device";
import { RequestsService } from "./utils/requests.service";
import { LoggerService } from "./utils/logger.service";
import { IAlert } from "../../shared/models/be/alert";

@Injectable({
    providedIn: 'root'
  })
export class AlertsService {    
    private alertSubject = new BehaviorSubject<IAlert | undefined>(undefined);
    public newAlert$ = this.alertSubject.asObservable();
  
    constructor(
      private _http: HttpClient,
      private requestsService: RequestsService,
      private loggerService: LoggerService
    ) {
    }

    public removeAlert(alert: IAlert) {

    }

    public saveNewAlert(alert: IAlert) {
        this.alertSubject.next(alert);
        const storedAlerts = [
            ...JSON.parse(localStorage.getItem('ALERTS_LIST') || "[]"),
            alert
        ];
        localStorage.setItem('ALERTS_LIST', JSON.stringify(storedAlerts));
    }

    public getAlerts$(): Observable<IAlert[]> {
        const currentUser = this.requestsService.getCurrentUser();
        /* return this._http.get<IAlert[]>(
            `${environment.apiUrl('devices')}/iot-devices/events`
        ).pipe(
            take(1),
            tap((alerts) => {
                this.alertsSubject.next(alerts);
                localStorage.setItem('ALERTS_LIST', JSON.stringify(alerts));
            }),
            catchError(err => {
                throw 'error in source. Details: ' + err;
            })
        ) */
        const alerts = [{
            id: "testtest",
            event_type: "Test",
            timestamp: new Date(),
            device_id: "460b9b37-0161-4f96-882e-9a135eca541e",
            metadata: null,
            person_id: null,
            bed_id: null,
            door_id: null,
            created_at: new Date()
        }]
        localStorage.setItem('ALERTS_LIST', JSON.stringify(alerts));
        return of(alerts)
    }
}