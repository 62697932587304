<ion-content [fullscreen]="true" class="page-content" color="light">
  <mat-tab-group>
    <mat-tab label="Struttura">
      <mat-accordion style="padding: 10px; display: flex; flex-direction: column;">
        @if (unitsTree.length) {
          <mat-expansion-panel *ngFor="let unit of unitsTree">
            <mat-expansion-panel-header>
              <mat-panel-title style="margin-left: 5px; font-size: 20px;"> {{unit.name}} </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-grid-list cols="8" rowHeight="36" gutterSize="5" style="margin: 5px 0;">
              <ng-container *ngFor="let room of unit.rooms; let i = index">
                <mat-grid-tile colspan="4" rowspan="2" [ngStyle]="{'background-color': room.alert ? '#E55466' : '#D9D9D9'}" style="border-radius: 5px;" (click)="openModal(room, unit)">
                  <mat-grid-list cols="10" rowHeight="6" gutterSize="3" style="width: 100%; margin: 5px;">
                    <mat-grid-tile colspan="10" rowspan="1"></mat-grid-tile>
                    <mat-grid-tile colspan="2" rowspan="4" style="overflow: visible;">
                      <mat-icon class="material-symbols-rounded" [matBadge]="room.patients?.length" matBadgeSize="small">person</mat-icon>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="4" rowspan="4">
                    </mat-grid-tile>
                    <mat-grid-tile colspan="4" rowspan="5" style="overflow: visible;">
                      <mat-icon *ngIf="room.alert" class="material-symbols-outlined" style="height: 55px; width: 55px; font-size: 55px;">falling</mat-icon>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="room.alert ? 6 : 10" rowspan="2" style="font-size: 18px; overflow: visible;">
                      <span style="position: absolute; top: -7px;">
                        {{room.name}}
                      </span>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="10" rowspan="1" style="font-size: 10px; overflow: visible;" *ngIf="room.alert">
                      <span>
                        ENTRATA STANZA | 60+ min
                      </span>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="10" rowspan="1"></mat-grid-tile>
                  </mat-grid-list>
                </mat-grid-tile>
              </ng-container>
            </mat-grid-list>
          </mat-expansion-panel>
        } @else {
          <div style="text-align: center;">
            <h1>Nessun sensore monitorabile</h1>
          </div>
        }
      </mat-accordion>
    </mat-tab>
    <mat-tab label="Allerte">
      @if (alertsList.length) {
          <mat-grid-list cols="9" rowHeight="36" gutterSize="10" style="margin: 15px;">
            <mat-grid-tile *ngFor="let alert of alertsList let i = index" colspan="9" rowspan="2" [ngStyle]="{'background-color': true ? '#E55466' : '#D9D9D9'}" style="border-radius: 5px;">
              <mat-grid-list cols="14" rowHeight="31" gutterSize="5" style="width: 100%; margin: 10px;">
                <mat-grid-tile colspan="3" rowspan="2" style="overflow: visible;">
                  <mat-icon class="material-symbols-outlined" style="height: 65px; width: 65px; font-size: 65px;">falling</mat-icon>
                </mat-grid-tile>
                <mat-grid-tile colspan="9" rowspan="1" style="font-size: 20px;">
                  CADUTA | 60+ min
                </mat-grid-tile>

                <mat-grid-tile colspan="2" rowspan="2" style="overflow: visible;">
                  <mat-icon class="material-symbols-rounded"  style="height: 45px; width: 45px; font-size: 45px;">videocam</mat-icon>
                </mat-grid-tile>
                <mat-grid-tile colspan="9" rowspan="1" style="font-size: 20px;">
                  Stanza 101 (Unità 1)
                </mat-grid-tile>
              </mat-grid-list>

            </mat-grid-tile>
          </mat-grid-list>
      } @else {
        <div style="text-align: center;">
          <h1>Nessuna allerta rilevata</h1>
        </div>
      }
    </mat-tab>
  </mat-tab-group>
</ion-content>