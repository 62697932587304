import { Component, ElementRef, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { FormComponent, ISelectInput, ISimpleInput } from '../form/form.component';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SanitizeInputDirective } from '../../directives/inputSanitize.directive';
import { MatInputModule } from '@angular/material/input';
import { IonicModule } from '@ionic/angular';
import { StreamingComponent } from '../streaming/streaming.component';
import { IAlert } from '../../models/be/alert';
import { TimingComponentsGroup } from '../timingComponentsGroup/timingComponentsGroup.component';

@Component({
  selector: 'app-dialog',
  templateUrl: './room-details-dialog.component.html',
  styleUrls: ['./room-details-dialog.component.scss'],
  standalone: true,
  imports: [ 
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatGridListModule,
    MatFormFieldModule,
    MatSelectModule,
    TranslateModule,
    ReactiveFormsModule,
    MatInputModule,
    IonicModule,
    TimingComponentsGroup,
    StreamingComponent
  ]
})
export class RoomDetailsDialogComponent implements OnInit {
  
  /**
   * @description FormGroup che regola tutti i campi della form nel component 
   */
  public formGroup!: FormGroup;
  public formArray!: FormArray;
  public hasError: boolean = false;
  public preferencesMode: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      patients: {id: string, name: string}[],
      alert?: IAlert
    },
  ) { }

  ngOnInit() {
  }
}
