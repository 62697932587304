import { Routes } from '@angular/router';
import { monitoringMobilePage } from '../monitoring/monitoring.mobile.component';
import { settingsMobilePage } from '../settings/settings.mobile.component';

export const MobileRootRoutes: Routes = [
  
  {
    path: "monitoring",
    component: monitoringMobilePage,
  },
  {
    path: "settings",
    component: settingsMobilePage
  },
  {
    path: '',
    redirectTo: 'monitoring',
    pathMatch: 'full',
  }
];
