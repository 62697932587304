import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { environment } from '../../../environments/environment';
import { Observable, catchError, of, take, tap } from "rxjs";
import { createPatientError, createPatientsuccess, loadingPatients, loadingPatientsError } from "../../state/actions/patients.actions";
import { IPatient } from "../../shared/models/be/patients";
import { Wound } from "../../shared/models/be/omnidermal.model";
import { IEvent } from "../../shared/models/be/events";

@Injectable({
    providedIn: 'any',
  })
export class EventService {
  
    constructor(
      private store: Store,
      private _http: HttpClient
    ) {}

    /**
     * @description Carica la lista dei pazienti nello store e li ritorna al subscribe
     */
    public loadEvents$(): Observable<{events: IEvent[]}> {
        return of({events: [{
            id: "cb3f6a24-3d7b-4c50-b492-a50952056831",
            patientId: "edc685bf-ccb0-461a-ac12-a916cb2bcc05",
            eventType: "medication",
            patient: "Mario Rossi",
            executionDate: "09/09/2024",
            room: "101",
            bed: "001",
            status: "completed",
            completedBy: "Jacopo Stefanuto",
            details: "Kerracel",
            bedSoreDetails: "Piede DX"
        },{
            id: "cb3f6a24-3d7b-4c50-b492-a50952056831",
            patientId: "edc685bf-ccb0-461a-ac12-a916cb2bcc05",
            eventType: "cleansing",
            patient: "Mario Rossi",
            executionDate: "09/09/2024",
            room: "101",
            bed: "001",
            status: "completed",
            completedBy: "Jacopo Stefanuto",
            details: "Kerracel",
            bedSoreDetails: "Piede DX"
        },{
            id: "cb3f6a24-3d7b-4c50-b492-a50952056831",
            patientId: "edc685bf-ccb0-461a-ac12-a916cb2bcc05",
            eventType: "mobilitation",
            patient: "Mario Rossi",
            executionDate: "09/09/2024",
            room: "101",
            bed: "001",
            status: "completed",
            completedBy: "Jacopo Stefanuto",
            details: "Kerracel",
            bedSoreDetails: "Piede DX"
        },{
            id: "cb3f6a24-3d7b-4c50-b492-a50952056831",
            patientId: "edc685bf-ccb0-461a-ac12-a916cb2bcc05",
            eventType: "evaluation",
            patient: "Mario Rossi",
            executionDate: "09/09/2024",
            room: "101",
            bed: "001",
            status: "completed",
            completedBy: "Jacopo Stefanuto",
            details: "Barthel, Albumin",
            bedSoreDetails: "Piede DX",
            itemsList: ['barthel', 'albumin']
        }]})
        //this.store.dispatch(loadingEvents());
        /* return this._http.get<{events: IEvent[]}>(
        `${environment.apiUrl('ehr')}/ehr/events`
        ).pipe(
            take(1),
            tap((patients) => {
                this.store.dispatch(loadPatientsSuccess(events))
            }),
            catchError(err => {
                this.store.dispatch(loadingPatientsError())
                throw 'error in source. Details: ' + err;
            })
        ) */
    }

    /* public createPatient$(patient: ICustomer) {
        of({message: 'Ok'}).pipe(
            tap(() => {
                this.store.dispatch(createCustomerSuccess({patient}))
            }),
            catchError(err => {
                this.store.dispatch(createCustomerError())
                throw `error in source. Details:  + ${err}`
            })
        ).subscribe();
    } */


    public getPatient$(patientId: string) {
        return this._http.get<IPatient>(
            `${environment.apiUrl('ehr')}/ehr/patients/${patientId}`
            ).pipe(
                take(1),
            tap((patient: IPatient) => {
                //this.store.dispatch(loadCustomersSuccess({customers}))
            }),
            catchError(err => {
                this.store.dispatch(loadingPatientsError())
                throw 'error in source. Details: ' + err;
            })
        )
    }

  public getPatientWounds$(patientId: string): Observable<Wound[]> {
    return this._http.get<Wound[]>(
        `${environment.apiUrl('ehr')}/ehr/omnidermal/wound/eaafbefd-87ef-42c7-b899-02463b844334`//${patientId}`
        ).pipe(
            take(1),
            catchError(err => {
                this.store.dispatch(loadingPatientsError())
                throw 'error in source. Details: ' + err;
            })
        )
  }

  public getPatientActivities$(patientId: string): Observable<Wound[]> {
    return this._http.get<Wound[]>(
        `${environment.apiUrl('ehr')}/ehr/omnidermal/wound/${patientId}`
        ).pipe(
            take(1),
            catchError(err => {
                this.store.dispatch(loadingPatientsError())
                throw 'error in source. Details: ' + err;
            })
        )
  }

  public createPatient$(patient: IPatient) {
    this._http.post(
        `${environment.apiUrl('ehr')}/ehr/patients`,
        patient
    ).pipe(
        take(1),
        tap(() => {
            this.store.dispatch(createPatientsuccess({patient}))
        }),
        catchError(err => {
            this.store.dispatch(createPatientError())
            throw `error in source. Details:  + ${err}`
        })
    ).subscribe();
  }

  public editPatient$(patient: IPatient) {
    this._http.put(
        `${environment.apiUrl('ehr')}/ehr/patients`,
        patient
    ).pipe(
        tap(() => {
            this.store.dispatch(createPatientsuccess({patient}))
        }),
        catchError(err => {
            this.store.dispatch(createPatientError())
            throw `error in source. Details:  + ${err}`
        })
    ).subscribe();
  }
  
  public deletePatient$(patientId: string) {
    this._http.delete(
        `${environment.apiUrl('ehr')}/ehr/patients/${patientId}`
    ).pipe(
        tap(() => {
            //this.store.dispatch(createPatientsuccess({patient}))
        }),
        catchError(err => {
            //this.store.dispatch(createPatientError())
            throw `error in source. Details:  + ${err}`
        })
    ).subscribe();
  }
}