import { Component, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import {
  combineLatest,
  Subject,
  tap,
} from 'rxjs';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { EventService } from '../../../../core/services/events.service';
import {MatToolbarModule} from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatBadgeModule } from '@angular/material/badge';
import { FacilitiesService } from '../../../../core/services/facilities.service';
import { RequestsService } from '../../../../core/services/utils/requests.service';
import { SensorService } from '../../../../core/services/sensors.service';
import { IUnit } from '../../../../shared/models/be/unit';
import { IRoom } from '../../../../shared/models/be/room';
import { MatDialog } from '@angular/material/dialog';
import { RoomDetailsDialogComponent } from '../../../../shared/components/roomDetailsDialog/room-details-dialog.component';
import { IDevice } from '../../../../shared/models/be/device';
import { AlertsService } from '../../../../core/services/alerts.service';
import { IAlert } from '../../../../shared/models/be/alert';

@Component({
  selector: 'monitoringMobile',
  templateUrl: './monitoring.mobile.component.html',
  styleUrls: ['./monitoring.mobile.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    MatGridListModule,
    MatIconModule,
    TranslateModule,
    RouterModule,
    MatGridListModule,
    IonicModule,
    MatToolbarModule,
    MatTabsModule,
    MatExpansionModule,
    MatBadgeModule
]
})
export class monitoringMobilePage implements OnInit {

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public eventService: EventService,
    public facilitiesService: FacilitiesService,
    private requestsService: RequestsService,
    public dialog: MatDialog,
    private sensorService: SensorService,
    private alertService: AlertsService
  ) {
  }

  ionViewDidEnter() {
  }

  private destroyed$ = new Subject();
  public installPrompt?: any;
  public unitsTree: (IUnit)[] = [];
  public devicesList: IDevice[] = [];
  public alertsList: IAlert[] = [];

  ngOnInit() {
    window.addEventListener("beforeinstallprompt", (event) => {
      event.preventDefault();
      this.installPrompt = event;
    });
    combineLatest([
      this.requestsService.currentUser$,
      this.sensorService.getDevices$(),
      this.alertService.getAlerts$()
    ]).pipe(
      tap(([user, devices, alerts]) => {
        this.alertsList = alerts;
        this.devicesList = devices;
        user?.building?.id && this.facilitiesService.getBuilding$(user?.building?.id, true).pipe(
          tap((building) => {
            this.unitsTree = (building.units || []).reduce((acc, curr) => {
              let currentUnitDevices = devices.filter((device) => device.facilities?.unit?.id === curr.id);
              if (currentUnitDevices.length) {
                acc.push({
                  ...curr,
                  rooms: curr.rooms?.filter((room) => currentUnitDevices.map((device) => device.facilities?.room?.id).includes(room.id)).map((room) => {
                    let currRoomDevices = devices.filter((device) => device.facilities?.room?.id === room.id);
                    return {
                      ...room,
                      devices: currRoomDevices,
                      alert: alerts.find((alert) => currRoomDevices.some((device) => alert.device_id === device.id))
                    }
                  })
                })
              }
              return acc;
            }, [] as IUnit[]);
          })
        ).subscribe();
      })
    ).subscribe();

    this.alertService.newAlert$.pipe(
      tap((alert) => {
        this.unitsTree.forEach((unit) => {
          unit.rooms?.forEach((room) => {
            if (room.devices?.some((device) => device.id === alert?.device_id)) {
              room.alert = alert;
            }
          })
        })
      })
    ).subscribe();
  }

  openModal(room : IRoom, unit: IUnit) {
    const dialogRef = this.dialog.open(RoomDetailsDialogComponent, {
      data: {
        title: `${room.name} (${unit.name})`,
        patients: room.patients,
        alert: room.alert
      },
      width: '95vw',
      maxWidth: '95vw'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(1);
    this.destroyed$.complete();
  }

  showInstallPwaPrompt() {
    this.installPrompt.prompt();
  }
}