import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class HardwareService {
    
    constructor() {
    }

    /**
     * Funzione che verifica che la webApp stia girando in PWA
     * @returns Se sta girando in PWA torna true, altrimenti false
     */
    isPwa(): boolean {
        if (document.referrer.startsWith('android-app://'))
            return true;//'twa';
        else if (window.matchMedia('(display-mode: standalone)').matches)
            return true;//'standalone';
        else if (window.matchMedia('(display-mode: minimal-ui)').matches)
            return true;//'minimal-ui';
        else if (window.matchMedia('(display-mode: fullscreen)').matches)
            return true;//'fullscreen';
        else if (window.matchMedia('(display-mode: window-controls-overlay)').matches)
            return true;//'window-controls-overlay';  
        else 
            return false;
    }
    
    /**
     * Funzione che verifica che la webApp stia girando su Mobile
     * @returns Se sta girando su Mobile torna true, altrimenti false
     */
    isMobile() {
        if ("maxTouchPoints" in navigator) {
            return navigator.maxTouchPoints > 0;
        } else if ("msMaxTouchPoints" in navigator) {
            return (navigator as any).msMaxTouchPoints > 0;
        } else {
            var mQ = matchMedia("(pointer:coarse)");
            if (mQ && mQ.media === "(pointer:coarse)") {
                return !!mQ.matches;
            } else if ('orientation' in window) {
                return true; // deprecated, but good fallback
            } else {
                // Only as a last resort, fall back to user agent sniffing
                var UA = (navigator as any).userAgent;
                return (
                    /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
                    /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
                );
            }
        }
    }
}