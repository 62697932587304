<div style="display: flex; justify-content: space-between; align-items: center; padding: 10px; background-color: rgb(219, 219, 219);">
  <mat-icon (click)="preferencesMode = !preferencesMode">{{preferencesMode ? 'arrow_back' : 'settings'}}</mat-icon>
  <h2 style="margin: 0;">{{preferencesMode ? 'Temporizzazione' : data.title}}</h2>
  <mat-icon [mat-dialog-close]="false" [ngStyle]="{'visibility': preferencesMode ? 'hidden' : null}">close</mat-icon>
</div>

<mat-dialog-content class="mat-typography" style="padding: 5px;">
  @if (!preferencesMode) {
    <mat-grid-list cols="9" rowHeight="36px" gutterSize="20">
      <mat-grid-tile colspan="5" rowspan="1" style="background-color: rgb(219, 219, 219); border-radius: 20px; padding: 5px;" *ngFor="let patient of data.patients">
        <mat-icon style="margin: 0 10px;">person</mat-icon>
        {{patient.name}}
      </mat-grid-tile>

      <mat-grid-tile colspan="9" rowspan="5" style="background-color: red;">
        <streaming style="height: 100%; width: 100%;"></streaming>
      </mat-grid-tile>

      <mat-grid-tile colspan="9" rowspan="3" *ngIf="data.alert">
        <div style="display: flex; flex-direction: column; border-radius: 5px; width: 100%; height: 100%; background-color: rgb(219, 219, 219); padding: 10px;">
          <div style="display: flex; justify-content: space-between;">
            <p>Allerta Caduta</p>
            <p>5 min fa</p>
          </div>
          <div style="height: 100%; display: flex; justify-content: space-between;">
            <ion-button color="medium" mode="md" [mat-dialog-close]="null" style="width: 33%; height: 100%;">
              Falsa allerta
            </ion-button>
            <ion-button color="primary" mode="md" [mat-dialog-close]="null" style="width: 65%; height: 100%;">
              Gestisci allerta<!-- {{'GENERAL.BUTTONS.SAVE_AND_CLOSE' | translate}} -->
            </ion-button>
          </div>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  } @else {
    <timingComponentsGroup></timingComponentsGroup>
  }
</mat-dialog-content>
