<div style="display: flex; justify-content: space-between; align-items: center; padding: 10px; background-color: rgb(219, 219, 219);">
  <mat-icon style="visibility: hidden;">settings</mat-icon>
  <h2 style="margin: 0;">Temporizzazione</h2>
  <mat-icon [mat-dialog-close]="false">close</mat-icon>
</div>

<mat-dialog-content class="mat-typography" style="padding: 5px;" (scroll)="onScroll()" [ngStyle]="{'overflow': isHandleTouched ? 'hidden' : ''}">
    <mat-expansion-panel *ngFor="let unit of unitsTree" (opened)="refreshCirclePosition()">
      <mat-expansion-panel-header style="background-color: #80808030;">
        <mat-panel-title style="margin-left: 15px; font-size: 18px;"> {{unit.name}} </mat-panel-title>
      </mat-expansion-panel-header>
      <timingComponentsGroup></timingComponentsGroup>
    </mat-expansion-panel>
</mat-dialog-content>
