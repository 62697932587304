
<mat-toolbar style="width: 100%; background-color: rgb(219, 219, 219); display: flex; justify-content: space-between;">
  <button mat-icon-button class="example-icon" aria-label="menu button" style="background-color: transparent;" (click)="isHome ? drawer.toggle() : goHome()">
    <mat-icon>{{isHome ? 'menu' : 'arrow_back'}}</mat-icon>
  </button>
  <span>{{buildingName}}</span>
  <button mat-icon-button class="example-icon favorite-icon" aria-label="settings" style="background-color: transparent;" [ngStyle]="{'visibility': isHome ? null : 'hidden'}" (click)="openTimingDialog()">
    <mat-icon>settings</mat-icon>
  </button>
</mat-toolbar>

<mat-drawer-container class="example-container" hasBackdrop="true">
  <mat-drawer #drawer mode="over">
      <!-- MENU ITEM LIST -->
      <mat-nav-list style="padding: 0;">
        <ng-container *ngFor="let item of menuItems">
          <a [routerLink]="item.routerLink || undefined" (click)="isHome = false; item.id === 'logout' ? logout() : null; drawer.toggle()" style="color: inherit; text-decoration: none;">
            <mat-list-item>
              <mat-icon matListItemIcon>{{item.icon}}</mat-icon>
              <div matListItemTitle>{{'SIDENAV.ITEMS.'+item.label | translate}}</div>
            </mat-list-item>
          </a>
          <!-- DIVIDER -->
          <mat-divider></mat-divider>
        </ng-container>
      </mat-nav-list>
  </mat-drawer>
  <mat-drawer-content>
    <ion-content [fullscreen]="true" [scrollY]="false">
      <router-outlet></router-outlet>
    </ion-content>
  </mat-drawer-content>
</mat-drawer-container>


