import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { getMessaging, getToken, MessagePayload, onMessage } from "firebase/messaging";
import { onBackgroundMessage } from "firebase/messaging/sw";
import { FirebaseApp, initializeApp } from 'firebase/app';
import { ToastService } from './utils/toast.service';
import { AlertsService } from './alerts.service';
import { catchError, Observable, take } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { RequestsService } from './utils/requests.service';
import { IAlert } from '../../shared/models/be/alert';

@Injectable({ providedIn: 'any' })
export class NotificationService {

    message?: MessagePayload;

    app: FirebaseApp;
    
    constructor(
      private toastService: ToastService,
      private _http: HttpClient,
      private alertsService: AlertsService,
      private requestsService: RequestsService
    ) {
        this.app = initializeApp(environment.firebase);
    }

    requestPermission() {
  
      const messaging = getMessaging();
  
      getToken(messaging, { vapidKey: environment.firebase.vapidKey }).then((currentToken) => {
        if (currentToken) {
          const currentUser = this.requestsService.getCurrentUser();
          this.postToken$(currentToken, currentUser?.building?.id || "").subscribe();
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  
    }

    listen() {
      const messaging = getMessaging();
      onMessage(messaging, (payload) => {
        console.log('Message received. ', payload);
        this.toastService.showToast(payload.notification?.title || "Notifica di test")
        this.alertsService.saveNewAlert(payload.data as unknown as IAlert)
        /* this.alertsService.saveNewAlert({
          id: "testtest",
          event_type: "Test",
          timestamp: new Date(),
          device_id: "89b5ef81-afb3-45f5-a237-5b07313ca22b",
          metadata: null,
          person_id: null,
          bed_id: null,
          door_id: null,
          created_at: new Date()
        }) */
      });
    }

    public postToken$(token: string, buildingId: string): Observable<any> {
      return this._http.post(
          `${environment.apiUrl('notifications')}/notifications/register-token`,
          {
            token,
            buildingId
          }
      ).pipe(
          take(1),
          catchError(err => {
              throw `error in source. Details:  + ${err}`
          })
      );
    }
}